﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

main {

    .superior-banner {
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 45px;
        position: relative;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        //When overlay is applied to the banner, text color will change to not get lost in the overlay / filter
        &.overlay {
            .content {
                color: white;
            }
        }

        .banner-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .full-column {
            @include make-col-ready();
            @include make-col(12);
        }

        &.animated {
            &.normal {
                height: 510px;
            }

            $sky-color: #bfdbf1;
            position: relative;
            background-color: $sky-color;
            overflow: hidden;

            .bottom {
                height: 20px;
                background-image: url("/dist/img/cloud_bottom.png");
                background-repeat: repeat-x;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
            }

            .cloud-1-screen {
                position: absolute;
                top: 0;
                left: -100%;
                animation: cloud1 36s linear infinite;
                width: 100%;
                height: 100%;
            }

            .cloud-2-screen {
                position: absolute;
                top: 0;
                left: -100%;
                animation: cloud2 66s linear infinite;
                width: 100%;
                height: 100%;
            }

            .cloud-3-screen {
                position: absolute;
                top: 0;
                left: -100%;
                animation: cloud3 66s linear infinite;
                width: 100%;
                height: 100%;
            }

            .cloud-holder {
                position: relative;
                height: 100%;
                width: 100%;
            }

            .cloud-1 {
                position: absolute;
                top: 55%;
                left: 110%;
                background-image: url("/dist/img/cloud1.png");
                background-repeat: no-repeat;
                width: 144px;
                height: 88px;
            }

            .cloud-1-wrap {
                position: absolute;
                top: 55%;
                left: 10%;
                background-image: url("/dist/img/cloud1.png");
                background-repeat: no-repeat;
                width: 144px;
                height: 88px;
            }

            .cloud-2 {
                position: absolute;
                top: 10%;
                left: 50%;
                background-image: url("/dist/img/cloud2.png");
                background-repeat: no-repeat;
                width: 83px;
                height: 54px;
            }

            .cloud-2-wrap {
                position: absolute;
                top: 10%;
                left: 150%;
                background-image: url("/dist/img/cloud2.png");
                background-repeat: no-repeat;
                width: 83px;
                height: 54px;
            }

            .cloud-3 {
                position: absolute;
                top: 70%;
                left: 90%;
                background-image: url("/dist/img/cloud3.png");
                background-repeat: no-repeat;
                width: 93px;
                height: 57px;
            }

            .cloud-3-wrap {
                position: absolute;
                top: 70%;
                left: 190%;
                background-image: url("/dist/img/cloud3.png");
                background-repeat: no-repeat;
                width: 93px;
                height: 57px;
            }

            .hotair-1, .hotair-2, .hotair-3 {
                display: block;
                position: absolute;
                background-image: url("/dist/img/hot_air_balloon.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 137px;
                height: 200px;
            }

            .hotair-1 {
                top: 12%;
                left: 20%;
                width: 137px;
                height: 200px;
                animation: hotair1 2.5s ease-in-out infinite;
            }

            .hotair-2 {
                top: 52%;
                left: 40%;
                width: 137px;
                height: 110px;
                animation: hotair2 6.4s ease-in-out infinite;
            }

            .hotair-3 {
                top: 7%;
                right: 8%;
                width: 137px;
                height: 140px;
                animation: hotair3 9s linear infinite;
            }

            &.short {
                min-height: 125px;

                .bottom {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                .hotair-1, .hotair-2, .hotair-3 {
                    display: none !important;
                }

                .hotair-1 {
                    left: 0%;
                }

                height: 400px;
            }
        }

        &.non-animated {
            &.normal {
                height: 490px;
            }

            background-position: center center;

            .banner-mobile {
                display: none;

                @include media-breakpoint-down(sm) {
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top center;
                }
            }

            &.circles-bottom.normal {

                .bottom {
                    height: 20px;
                    background-image: url("/dist/img/cloud_bottom.png");
                    background-repeat: repeat-x;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                }

                @include media-breakpoint-up("md") {
                    height: 510px;
                }
            }
        }

        & > .container {
            position: relative;
            z-index: 3;
        }

        .bubble {
            float: right;
            margin-top: 15px;
            background-color: $sup-aqua-blue;
            color: $white;
            width: 460px;
            height: 460px;
            border-radius: 50%;
            position: relative;
            padding: 30px;
            text-align: center;
            display: flex;
            align-content: center;
            justify-content: center;
            flex-direction: column;
            z-index: 1;

            .bubble-content {
                flex: none;

                .hotair-baloon {
                    display: inline-block;
                    position: relative;
                    width: 17%;

                    img {
                        width: 100%;
                        margin-top: -150%;
                        height: 100%;
                    }
                }

                > * ~ * {
                    margin-top: 1rem;
                }
            }

            .small-title {
                font-size: 32px;
                line-height: 1;
                text-shadow: -0.03em 0.09em 0.01em $heading-font-shadow;
            }

            .large-title {
                font-size: 56px;
                line-height: 1;
                text-shadow: -0.02em 0.06em 0.00em $heading-font-shadow;
                font-weight: bold;
            }

            .text-content {
                font-size: 24px;
                text-shadow: -0.03em 0.07em 0.01em $heading-font-shadow;

                p {
                    margin-bottom: 0.25rem;
                    line-height: normal;
                }

                :last-child {
                    margin-bottom: 0;
                }
            }

            a.btn, .coupon {
                display: inline-block;
                background-color: $sup-orange;
                color: $white;
                font-size: 20px;
                font-weight: 600;
                padding-left: 1em;
                padding-right: 1em;
                min-width: 180px;

                &:hover {
                    background-color: $sup-orange-darker;
                }
            }

            .coupon {
                display: inline-block;
                font-size: 20px;
                font-family: $headings-font-family;
                font-weight: 600;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                vertical-align: middle;

                &:hover {
                    background-color: $sup-orange;
                }
            }

            .zipcodecheck {
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
                display: table;
                max-width: 100%;
                text-align: left;

                form {
                    min-width: 210px;
                }

                .btn {
                    border-radius: 0;
                    font-weight: bold;
                    font-size: 14px;
                    letter-spacing: 1px;
                }

                input {
                    margin-bottom: 0.5rem;
                }

                label {
                    font-size: 14px;
                    line-height: 1;
                    margin-bottom: 0.5rem;
                }
            }

            @include media-breakpoint-down(xs) {
                width: 290px;
                height: 290px;
                padding: 5%;
                float: none;
                margin-right: auto;
                margin-left: auto;
                margin-top: 40px;

                .bubble-content {
                    > * ~ * {
                        margin-top: 0.25rem !important;
                    }
                }

                .small-title {
                    font-size: 18px;
                    text-shadow: -0.03em 0.09em 0.01em $heading-font-shadow;
                }

                .large-title {
                    font-size: 32px;
                    text-shadow: -0.02em 0.06em 0.00em $heading-font-shadow;
                    font-weight: bold;
                }

                .text-content {
                    font-size: 16px;
                    text-shadow: -0.03em 0.07em 0.01em $heading-font-shadow;
                }

                .coupon {
                    font-size: 16px;
                    padding-right: 1rem;
                    padding-left: 1rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }

                .zipcodecheck {
                    line-height: normal;

                    label {
                        font-size: 14px;
                        line-height: normal;
                    }

                    .btn {
                        padding-top: 1px;
                        padding-bottom: 1px;
                        letter-spacing: 0;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    input {
                        padding-top: 1px;
                        padding-bottom: 1px;
                        margin-bottom: 0.25rem;
                    }

                    .error-message {
                        margin-top: -0.25rem;
                        margin-bottom: 0.25rem;
                    }
                }
            }

            @media( min-width: 400px ) and ( max-width: 575px ) {
                height: 370px;
                width: 370px;
            }
        }
        /*short banner*/
        &.short.animated, &.short.non-animated {
            height: auto;
            min-height: 125px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: flex-start;

            .short-title {
                text-align: center;
                font-family: $headings-font-family;
                font-size: 42px;
                font-weight: bold;
                line-height: 54px;
                margin-bottom: 0;
                padding-left: 50px;
                padding-right: 50px;

                @include media-breakpoint-down(sm) {
                    font-size: 32px;
                    line-height: 40px;
                    padding: 10px 0;
                }
            }

            .hotair-1, .hotair-2 {
                display: none !important;
            }

            .cloud-1, .cloud-1-wrap {
                top: 10%;
                z-index: 2;
            }

            .cloud-3, .cloud-3-wrap {
                top: 50%;
            }

            .hotair-3 {
                z-index: 2;
                right: 0%;

                @include media-breakpoint-down(lg) {
                    width: 94px;
                }
            }

            .bottom {
                display: none;
            }
        }

        &.short.animated {
            .short-title {
                color: $sup-slate-blue-bg;
            }
        }
    }
}

                @keyframes cloud1 {
                    0% {
                        left: -100%;
                    }

                    100% {
                        left: 0%;
                    }
                }

                @keyframes cloud2 {
                    0% {
                        left: -100%;
                    }

                    100% {
                        left: 0%;
                    }
                }

                @keyframes cloud3 {
                    0% {
                        left: -100%;
                    }

                    100% {
                        left: 0%;
                    }
                }

                @keyframes hotair1 {
                    0% {
                        top: 12%;
                    }

                    50% {
                        top: 18%;
                    }

                    100% {
                        top: 12%;
                    }
                }

                @keyframes hotair2 {
                    0% {
                        top: 52%;
                    }

                    50% {
                        top: 46%;
                    }

                    100% {
                        top: 52%;
                    }
                }

                @keyframes hotair3 {
                    0% {
                        top: 7%;
                    }

                    50% {
                        top: 14%;
                    }

                    100% {
                        top: 7%;
                    }
                }
