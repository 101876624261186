﻿/// <reference path = "../../main.scss" />
// File Name: _variables.scss
// Description: Project variables
// ------------------------------------------------------------

$font-family-sans-serif: 'Roboto', Arial, sans-serif !default;
$headings-font-family: 'Fira Sans', Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$alt-font-1-font-family-sans-serif: 'Niramit', 'Roboto', Arial, sans-serif !default;
$alt-font-1-headings-font-family: 'Catamaran', 'Fira Sans', Arial, sans-serif !default;
$alt-font-1-font-family-base: $alt-font-1-font-family-sans-serif !default;

//$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
//$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//$font-family-base:       $font-family-sans-serif !default;

$sup-header-light-blue: #f8fefe;
$sup-slate-blue: #14375f;
$sup-slate-blue-pale: #285984;
$sup-slate-blue-lighter: #2b619f;
$sup-slate-blue-bg: #15334f;
$sup-light-gray: #f3f3f3;
$dark-blue: #15324D;
$testimonial-dark-blue: #14324C;
$sup-aqua-blue: #26cad3;
$sup-aqua-blue-lighter: #5ef5fc;
$sup-aqua-blue-active: #1abbc3;
$sup-aqua-blue-darker: #3babb7;
$java: #29b2ba;
$java-2: #27b1b9;
$st-tropaz: #275882;
$st-tropaz-2: #275984;
$black-squeeze: #f3fafa;
$timberwolf: #d9d5ca;
$bianca: #fbf8ef;
$pelorous: #3fbcc5;
$orange: #e98145;
$brighter-orange: #fb9c50;
$sup-drk-aqua-blue: #218191;
$elm: #1f8091;
$tundora: #494949;
$sup-site-gray: #deddd9;
$sup-site-gray-lighter: #f0efeb;
$sup-site-green: #61a649;
$sup-site-green-lighter: #9bce4b;
$sup-site-green-active: #7eb22a;
$sup-utility-style-brown: #ece8dc;
$sup-utility-style-brown-darker: #968756;
$sup-orange: #e8813e;
$sup-orange-darker: #bf6326;
$sup-tan-bg: #c8c2b2;
$sup-tan-bg-dark-text: #555249;
$sup-footer-gray: #0e0e18;
$sup-footer-dark-gray-bluer: #6f7d88;
$sup-footer-dark-gray-blue: #b3b3b3;
$sup-footer-light-gray-headings: #c1c1c1;

$heading-font-shadow: rgba(0,0,0,0.25);


$sample-site-blue: #1b32db;
$sample-site-gray: #1b32db;
$sample-site-green: #1bdb76;

//approved site theme colors so we can use this in sass loops with our approved color module
$approved-colors-map: ( blue: $sample-site-blue, green: $sample-site-green );

/**/
/* Color Definitions */
/**/
$_black: #000000;
$_white: #ffffff;
$black: $_black;
$white: $_white;

/**/
/* Generic Color Names */
/**/
$color1: #000000;
$color2: #081321;
$color3: #0a3866;
$color4: #2c6fb1;
$color5: #7fc6de;
$color6: #808080;
$color7: #a6a6a6;
$color8: #c13f2d;
$color9: #fa6e49;
$color10: #ebebeb;
$color11: #ffffff;

/**/
/* Calculated Colors */
/**/
$color12: mix($white,$color1, 15%); //#262626
$color13: mix($white,$color3, 15%); //#305e8c
$color14: mix($white,$color4, 15%); //#5295d7
$color15: mix($white,$color5, 15%); //#a5ecff
$color16: mix($white,$color6, 15%); //#5a5a5a
$color17: mix($black,$color8, 10%); //#a82614
$color18: mix($white,$color9, 10%); //#ff8863
$color19: mix($black,$color11, 3%); //#f7f7f7
$color20: mix($white,$color11, 3%); //#ffffff
$color21: #298e02; //Green check marks

/**/
/* Headings */
/**/
$headings-color: $color1;
$headings-link-color: $color4;
$headings-link-interact-color: $color5;
$headings-2-color: $color1;
$headings-3-color: $color2;
$headings-4-color: $color3;
$headings-5-color: $color12;
$headings-6-color: $color12;

//Primary
$primary-nav-top-border: $color1;
$primary-nav-borders: $color7;
$primary-nav-link-color: $black;
$primary-nav-link-border: $white;
$primary-nav-link-hover-border: $white;
$primary-nav-link-hover-bg: $sup-aqua-blue;
$primary-nav-link-hover-font-color: $white;
$primary-nav-dropdown-bg: $white;
$primary-nav-dropdown-arrow-color: $white;
$primary-nav-dropdown-link-color: $black;
$primary-nav-dropdown-link-hover-bg: $sup-aqua-blue;
$primary-nav-dropdown-link-hover-font-color: $white;

//Utility
$utility-nav-bg: $color2;
$utility-nav-link-color: $white;
$utility-nav-link-hover-bg: $color3;
$utility-nav-link-hover-font-color: $color20;
$utility-nav-dropdown-bg: $utility-nav-link-hover-bg;
$utility-nav-dropdown-hover-bg: $color14;
$utility-nav-dropdown-font-color: $utility-nav-link-hover-font-color;
$utility-nav-dropdown-hover-font-color: $utility-nav-link-color;


$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1600px ) !default;