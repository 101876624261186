﻿///<reference path="../../main.scss" />
// File Name: _business-hours-payment.scss
// Description: Styles for the testimonial
// ------------------------------------------------------------
.business-hours-payment-section {
    @include make-container();

    .business-hours-payment-row {
        @include make-row();
    }

    .business-hours {
        @include make-col(7);
        padding-right: 15px;

        .hours-line {
            .title {
                font-weight: bold;
            }
        }

        .notes {
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 40px;
            color: $tundora;

            ul > li {
                font-size: 14px;
                line-height: 26px;
            }

            p {
                margin-bottom: 0.6rem;
            }
        }
    }

    .payment-methods-accepted {
        @include make-col(5);

        .payment-methods-list {
            .payment-method {
                display: inline-block;
                margin-bottom: 25px;
                margin-right: 25px;
            }
        }
    }
}
