﻿/// <reference path = "../marathon.scss" />
// File Name: _nav-primary-mobile.scss
// Description: Mobile primary navigation styles
// ------------------------------------------------------------

.link-enabled {
    cursor: pointer;
}

.freeze {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.dynamic-form-container {
    display: none;
}

.height-auto {
    height: auto !important;
}

.hidden {
    display: none;
}

@include media-breakpoint-down(md) {
    main {
        position: relative;
    }

    .mobile-overlay {
        transition: all ease .5s;
        background-color: rgba(0,0,0,0.0);
        position: absolute;

        &.show {
            z-index: 999;
            background-color: rgba(0,0,0,0.8);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            overflow: hidden;

            &.out {
                background-color: rgba(0,0,0,0.0);
            }
        }
    }

    #menu {
        z-index: 1000;
        top: 100%;
        left: 0;
        position: absolute;

        &.domReady {
            display: none;
        }

        h2 {
            display: block;
            border-bottom: solid 2px #336ca6;
            color: #052e4d;
            display: table;

            i {
                display: table-cell;
                padding: .2em .2em;
            }

            img {
                float: right;
            }

            a {
                font-size: 1.2em;
                padding-right: 0;
                color: #052e4d;
            }

            display: none;
        }
        //Enable this style when not using 100% width mobile menu
        /*.multilevelpushmenu_wrapper:before {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $black;
            opacity: .5;
            content: "";
        }*/
        .multilevelpushmenu_wrapper {
            //position: fixed;
            background-color: transparent;
            overflow: visible;

            .ltr {
                box-shadow: none;
            }

            .backItemClass {
                background-color: $gray-600;
                border-top: 1px solid $gray-600;
                padding-left: 1.5rem;
                color: $white;

                a {
                    i {
                        padding: 0 .4em 0 0;
                    }
                }

                a:hover {
                    color: $white;
                }
            }

            .levelHolderClass {
                font-family: $font-family-sans-serif;
                background-color: transparent;
                overflow: visible;

                &.scrolled {
                    overflow-y: scroll;
                    height: 100%;
                    margin-right: 3px;
                    direction: rtl;

                    ::-webkit-scrollbar {
                        cursor: pointer;
                    }
                }

                > h2 {
                    margin-bottom: 0;
                    display: table;
                    width: 100%;

                    &:nth-child(2) {
                        margin-bottom: -1px;

                        a {
                            padding: .5em 0;
                        }
                    }

                    a {
                        display: table-cell;
                        vertical-align: middle;
                    }

                    display: none;
                }
            }

            .backItemClass {
                padding-left: 0;
            }

            li {
                border-top: solid 1px $nav-tabs-border-color;
                padding-left: 1.5rem;
                background-color: $gray-300;
                text-transform: uppercase;
                cursor: initial;
                padding: 0;

                a {
                    padding: .6em;
                }

                &:hover {
                    background-color: $gray-600;

                    > a {
                        color: $white;
                    }
                }
            }

            li:first-child {
                border-top: solid 1px $gray-400;
            }

            li:last-child {
                border-bottom: solid 1px $gray-400;
            }

            li.primary-nav-link {
                border-top: solid 1px $gray-400;
                background-color: $nav-tabs-border-color;
                text-transform: initial;

                &:hover {
                    background-color: $gray-400;
                }
            }

            ul.markup {

                li {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            a {
                line-height: 1.75rem;
                color: $gray-700;
                font-size: .93rem;

                i {
                    font-size: 1.5rem;
                    line-height: 1;
                }

                i.floatLeft {
                    float: right; // I realize this doesn't make sense...
                }
            }
        }
    }
}
