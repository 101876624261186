﻿///<reference path="../../main.scss" />
// File Name: _ie.scss
// Description: IE Fixes
// ------------------------------------------------------------

.ie11 {
    main .superior-banner.short.animated, main .superior-banner.short.non-animated{
        height: 125px;
    }
}
