﻿///<reference path="../../main.scss" />
// File Name: _text-card.scss
// Description: Styles for the Text Card
// ------------------------------------------------------------
main .text-card {
    text-align: center;
    margin-top: 15px;
    h2.teal-highlight-heading {
        font-size: 32px;
        text-align: center;
    }
}
section.TextCards-section{
    @include media-breakpoint-down(md)
    {
        margin-bottom: 15px;
    }
}
