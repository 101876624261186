﻿///<reference path="../../main.scss" />
// File Name: _grid-editor-related-links.scss
// Description: Styles for the testimonial
// ------------------------------------------------------------

.sidebar-region {

    .column-12.sidebarPicker {
        //padding-left: 0;
        //padding-right: 0;

        .col-md-12 {
            @media (max-width: 1200px) {
                //padding-left: 0;
                padding-top: 25px;
            }
        }

        .container {
            max-width: none;
        }
    }

    .related-links {
        border: none;
        border-radius: 0;
        font-size: 16px;

        .heading {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
            font-family: $headings-font-family;
            font-size: 16px;
            line-height: 26px;
        }

        .items {
            font-size: 15px;

            ul {
                margin-left: 0;
                padding-left: 0;
                margin-bottom: 0;

                li {
                    list-style-type: none;
                    margin-left: 0;
                    padding-left: 0;

                    a {
                        color: $black;
                        font-weight: lighter;
                        //padding: 4px 0;
                        margin: 5px 0;
                        display: inline-block;
                        border-bottom: 0;
                        font-size: 14px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
