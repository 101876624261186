﻿///<reference path="../../main.scss" />
// File Name: _locationDetails.scss
// Description: Styles for the Location Details Page
// ------------------------------------------------------------

.subpage {
    &.testimonialsContainer {
        .testimonials {
            @include media-breakpoint-up(lg) {
                flex: 0 0 80%;
            }

            margin: 10px auto 0;
        }

        .testimonial-wrapper {
            padding-bottom: 20px;

            .name {
                padding-top: 30px;
                font-family: $headings-font-family;
                font-size: 20px;
                color: $testimonial-dark-blue;
                text-align: right;
                position: relative;
                padding-right: 60px;
                padding-bottom: 15px;

                @include media-breakpoint-down(sm) {
                    padding-top: 15px;
                }

                @include media-breakpoint-down(xs) {
                    text-align: left;
                    padding-left: 10%;
                }

                p {
                    display: inline-block;
                    margin-bottom: 0;
                }

                img {
                    position: absolute;
                    right: 0;
                    @extend .img-fluid;

                    @include media-breakpoint-down(sm) {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .testimonial {
            background-color: $bianca;
            position: relative;
            padding: 30px 60px 30px 30px;

            @include media-breakpoint-down(md) {
                padding: 30px 45px 30px 30px;
            }

            @include media-breakpoint-down(sm) {
                padding: 30px 30px 30px 30px;
            }

            .triangle {
                position: absolute;
                bottom: -30px;
                right: 30px;
                width: 0;
                height: 0;
                width: 0;
                height: 0;
                border-left: 25px solid transparent;
                border-right: 25px solid transparent;
                border-top: 30px solid $bianca;

                @include media-breakpoint-down(sm) {
                    bottom: -20px;
                    right: 25px;
                }
            }

            p {
                margin-bottom: 0;
                font-size: 18px;
                line-height: 30px;
                color: $dark-blue;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                    line-height: 34px;
                }
            }
        }
    }
}
