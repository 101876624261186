﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

body {
    font-size: 18px;
    color: $black;
}

.teal {
    color: $java;
}

.teal-highlight-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: $pelorous;
    margin-bottom: 20px;
}

.blue-highlight-heading {
    color: $st-tropaz-2;
    font-size: 18px;
    font-style: italic;
}

a.skip-content {
    position: absolute;
    left: -10000px;
    top: 5px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

a.skip-content:focus {
    position: absolute;
    width: auto;
    height: auto;
    left: 5px;
    padding-right: 5px;
    background-color: $black;
    top: 5px;
    z-index: 1032;
}

header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 4;
    background-color: $sup-header-light-blue;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
}

main {
    padding-top: 0px;
    //margin-top: 95px;
    z-index: 1;
    /*
    @include media-breakpoint-down(md) {
        margin-top: 80px;
    }*/

    .headline h1, .subheading h2 {
        text-align: center;
    }

    h2 {
        font-size: 2.25rem;
    }

    h1, .h1Heading, h2 {
        //letter-spacing: 0.03em;
        color: $black;
        font-weight: normal;
    }

    h3, .h3Heading {
        font-size: 20px;
        margin-bottom: 5px;
    }

    h4.FourthLevelHeading {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 2px;
    }

    p {
        img {
            @extend .img-fluid;
        }
    }

    p.img-center {
        text-align: center;
        margin-bottom: 20px;
    }

    p.img-right {

        img {
            float: right;
            padding-left: 15px;
            padding-bottom: 15px;

            @include media-breakpoint-down(sm) {
                float: none;
                padding-left: 0;
                text-align: center;
            }
        }
    }

    p.img-left {

        img {
            float: left;
            padding-right: 15px;
            padding-bottom: 15px;

            @include media-breakpoint-down(sm) {
                float: none;
                padding-right: 0;
                text-align: center;
            }
        }
    }

    a {
        color: $elm;
        border-bottom: 1px dotted $elm;

        &:hover {
            color: darken($elm, 10%);
            text-decoration: none;
            border-bottom: 1px solid darken($elm, 10%);
        }
    }

    .tan-bg {
        background-color: $sup-tan-bg;
    }

    .tan-swirl {
        background-image: url("/dist/img/tan-swirl.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: $sup-tan-bg;
        padding-top: 127px;
        padding-bottom: 60px;
    }

    .blue-swirl {
        background-image: url("/dist/img/blue-swirl.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: $sup-slate-blue-bg;
        padding-top: 127px;
        padding-bottom: 60px;
        color: $white;

        .rte {
            a {
                text-decoration: none;
                border-bottom-color: $sup-orange;

                &:hover {
                    border-bottom-color: $sup-orange-darker;
                }
            }
        }

        h2 {
            color: $white;
        }

        a {
            color: $sup-orange;
            text-decoration: underline;

            &:hover {
                color: $sup-orange-darker;
            }
        }
    }

    hr {
        border-width: 2px;
    }

    .rte, .content-region {
        ul {
            padding-left: 15px;
            margin-bottom: 20px;

            li {
                position: relative;
                list-style: none;
                padding-bottom: 2px;
                font-size: 16px;
                line-height: 30px;

                a {
                    font-size: 16px;
                }

                &:before {
                    content: "*";
                    position: absolute;
                    left: -15px;
                    top: 3px;
                    color: $java-2;
                }
            }
        }
    }
}

#cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxMiddleLeft, #cboxMiddleRight, #cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight {
    width: 0;
    height: 0;
}

#cboxOverlay {
    background-image: none;
    background-color: rgba(0,0,0,0.7);
}

#cboxContent {
    position: relative;
    padding: 15px;

    > button, #cboxTitle {
        margin-bottom: 15px;
    }

    #cboxCurrent {
        margin-bottom: 11px;
    }
}

#cboxPrevious, #cboxNext, #cboxCurrent, #cboxTitle {
    margin-left: 15px;
}

#cboxClose {
    margin-right: 15px;
}

p {
    img {
        @extend .img-fluid;
    }
}

.breadcrumbs {
    ul {
        list-style-type: none;
        padding-left: 15px;

        li {
            display: inline-block;

            + li {
                padding-left: 15px;

                &:before {
                    content: "/";
                    padding-right: 15px;
                }
            }
        }
    }
}

.hidden-lg-up {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.hidden-md-down {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.tan {
    background-color: $bianca;

    &.container-fluid-bg {
        padding-top: 40px;
        padding-bottom: 120px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 80px;
        }
    }
}

.gray {
    background-color: $sup-light-gray;

    &.container-fluid-bg {
        padding-top: 40px;
        padding-bottom: 120px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 80px;
        }
    }
}

.teal-color {
    color: $pelorous;
}

.btn {
    padding: .35rem .75rem;
    border-radius: 10px;
    cursor: pointer;

    &.blue {
        @include outline-button($white, $st-tropaz, $white, darken($st-tropaz, 10%));
    }
}
