﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

main {

    .banner {
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 2rem;
        position: relative;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        //When overlay is applied to the banner, text color will change to not get lost in the overlay / filter
        &.overlay {
            .content {
                color: white;
            }
        }

        .banner-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .content {
            padding: 60px 0;
            @include make-col-ready();
            @include make-col(12);

            h1 {
                margin: 0;
                font-size: 1.5rem;

                @include media-breakpoint-up(md) {
                    font-size: 2rem;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 2.5rem;
                }
            }
        }
    }
}
