﻿/// <reference path = "../../main.scss" />
// File Name: _nav-utility.scss
// Description: Utility navigation styles
// ------------------------------------------------------------

@include media-breakpoint-up(lg) {
    body {

        main {
            /* Styling for Affixed */
            margin-top: 163px;
        }

        nav.nav-utility {
            position: fixed;
            top: 0;
            width: 100%;
            height: 35px;
            z-index: 2000 !important;
            //Needs to be a color from the pallet
            background-color: $utility-nav-bg;
        }

        nav.nav-primary {
            position: fixed;
            top: 35px;
            width: 100%;
            z-index: 1000 !important;
            background: white;
        }
    }
}

.header-utility {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

nav.nav-utility {
    .nav-link {
        font-size: 14px;
        padding: 0;
    }

    ul.navbar-utility {
        max-width: 1600px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 16px;
        list-style: none;
        @extend .container-fluid;

        li {
            display: inline-block;
            padding: 9px 15px;

            a, i {
                text-decoration: none;
                //Probably needs to be a color from the pallet
                color: $utility-nav-link-color;
            }

            &:hover {
                //background-color: lighten(#02232C,20%);
                background-color: $utility-nav-link-hover-bg;

                a, i {
                    color: $utility-nav-link-hover-font-color;
                }
            }

            &.dropdown {
                right: 0;

                > a:after {
                    content: "\f0d7";
                    padding-left: .5rem;
                    font: normal normal normal 14px/1 FontAwesome;
                    //Probably needs to be a color from the pallet
                    color: $utility-nav-link-color;
                }

                &:hover {
                    > .dropdown-menu {
                        display: block;
                    }
                }

                &.account-dropdown {
                    background-color: $utility-nav-dropdown-bg;
                }

                .dropdown-menu {
                    //Probably needs to be a color from the pallet (same as hover color though)
                    background-color: $utility-nav-dropdown-bg;
                    min-width: 12rem;
                    margin: 0;
                    border: none;
                    border-radius: 0;
                    right: 0;
                    left: initial;
                    padding: 0;

                    ul {
                        padding-left: 0;

                        li {
                            width: 100%;
                            padding: 15px 20px;

                            &:hover {
                                background-color: $utility-nav-dropdown-hover-bg;

                                a, i {
                                    color: $utility-nav-dropdown-hover-font-color;
                                }
                            }

                            i {
                                padding-right: 5px;
                            }

                            a, i {
                                color: $utility-nav-dropdown-font-color
                            }
                        }
                    }
                }
            }
        }
    }
}