﻿@include media-breakpoint-down(md) {
    .mobile-schedule-pickup {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000;

        a {
            width: 100%;
            max-width: none;
            border-radius: 0;
            padding: 10px 0;
            border-radius: 0 !important;
        }
    }

    nav.nav-primary {
        nav {
            .mobile-header-wrapper {
                flex-wrap: nowrap;
            }
        }
    }

    main {
        margin-top: 80px;
    }

    .nav-primary {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000 !important;
        background: white;
        background-color: #f8fefe;
        -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.25);
        box-shadow: 0 0 12px rgba(0,0,0,0.25);
    }

    .utility-col-mobile {
        -ms-flex-order: 2;
        order: 2;
        float: none;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: right;
        padding-right: 20px;
    }


    nav.nav-primary .mobile-header-wrapper {
        border-bottom: $primary-nav-borders solid 1px;
        justify-content: space-between;
        display: block;
        -ms-flex-direction: row !important;
        -webkit-flex-direction: row !important;
        flex-direction: row !important;
        align-items: flex-start;
        align-content: flex-start;

        @media (max-width: 500px) {
            flex-wrap: nowrap;
        }

        .utility-col-mobile {
            order: 2;
            float: none;
            flex: 1 1 auto;
            text-align: right;
            padding-right: 20px;

            .utility-item {
                display: inline-block;
                padding: 0 20px;

                a {
                    color: $primary-nav-link-hover-bg;
                    font-size: 38px;
                    /*
                    @media (max-width: 620px) {
                        font-size: 28px;
                    }*/
                }
            }

            @media (max-width: 500px) {
                /*display: block;
                width: 100%;
                padding: 0 20px;
                text-align: center;*/
                .utility-item {
                    &.hours, &.phone {
                        display: none;
                    }
                }
            }
        }

        #mobileNavbarToggler {
            height: 64px;
            width: 64px;
            order: 3;
            //align-self: flex-end;
            float: none;
            flex: 0 0 auto;
            margin: 0;
            padding: 0;
            padding-right: 15px;

            > div {
                background: $primary-nav-link-hover-bg;
                color: $primary-nav-link-hover-font-color;
                padding: 5px 10px;

                span {
                    font-size: 32px;
                }
            }

            &.closeLabel {
                background: $primary-nav-link-hover-bg;
                color: $primary-nav-link-hover-font-color;
                height: 56px;
                width: 47px;
                margin-right: 15px;
                text-align: center;
                padding-right: 0;
            }

            @media (max-width: 620px) {
                width: auto;
                height: auto;
            }

            @media (max-width: 500px) {
                order: 3;
            }
        }

        .navbar-brand {
            order: 1;
            float: none;
            align-self: flex-start;
            width: 300px;
            margin-right: 0;
            flex: 0 0 auto;
            margin-left: 0;
            padding-left: 15px;

            @media (max-width: 620px) {
                width: 200px;
            }

            @media (max-width: 500px) {
                order: 1;
                width: 150px;
            }
        }
    }

    #menu {
        .multilevelpushmenu_wrapper {
            li {
                &.utility-nav-style {
                    border-color: $sup-utility-style-brown;
                    background: $sup-utility-style-brown;

                    > a {
                        color: $primary-nav-link-color;
                    }

                    &:hover {
                        border-color: $sup-utility-style-brown-darker;
                        background: $sup-utility-style-brown-darker;

                        > a {
                            color: $primary-nav-link-hover-font-color;
                        }
                    }
                }

                &.utility-navLinks {
                    border-color: $utility-nav-bg;
                    background: $utility-nav-bg;

                    > a {
                        color: $utility-nav-link-color;
                    }

                    &:hover {
                        border-color: $utility-nav-link-hover-bg;
                        background: $utility-nav-link-hover-bg;

                        > a {
                            color: $utility-nav-link-hover-font-color;
                        }
                    }
                }
            }
        }
    }
}
