﻿/// <reference path = "../../main.scss" />
// File Name: _button.scss
// Description: Special styles
// ------------------------------------------------------------
.special {
    background-color: $sup-aqua-blue;
    color: $white;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    z-index: 50;

    .special-container {
        @include make-container();
        @include make-container-max-widths();
        position: relative;

        .bird {
            display: inline-block;
            position: absolute;
            margin-left: -220px;
            width: 114px;
            height: 98px;
            /*top: 12px;*/
            bottom: -55px;
            z-index: 1;

            .bird-feet {
                background-image: url("/dist/img/birdsing_feet.png");
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }

            .bird-body {
                animation: birdsing_footer_rotate 1.3s ease infinite;
                width: 100%;
                height: 100%;
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;

                .bird-img {
                    background-image: url("/dist/img/birdsing_nofeet.png");
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    animation: birdsing_footer 1.3s steps(2) infinite;
                }
            }

            @include media-breakpoint-down(md) {
                margin-left: -140px;
                /*top: 96px;
                bottom: unset;*/
                left: 30%;
                bottom: -55px;
                top: inherit;
            }

            @include media-breakpoint-down(sm) {
                margin-left: -134px;
                margin-top: -136px;
                left: inherit;
                bottom: inherit;
            }
        }

        @include media-breakpoint-down(sm) {
            padding-top: 90px;

            .bird {
                left: 50%;
                margin-left: -57px;
                top: 110px;
            }
        }

        .special-data {
            text-align: center;
            padding-left: 114px;

            .bold-title {
                font-size: 38px;
                text-shadow: -0.02em 0.06em 0.00em $heading-font-shadow;
                line-height: 38px;
                font-weight: 600;
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 5px;
            }

            .text {
                margin-left: 1rem;
                margin-right: 1.5rem;
                font-size: 26px;
                text-shadow: -0.02em 0.06em 0.00em $heading-font-shadow;
                line-height: 26px;
                font-weight: 300;
                display: inline-block;
                vertical-align: middle;
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .btn, .coupon {
                background-color: $sup-orange;
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                &:hover {
                    background-color: $sup-orange-darker;
                }
            }

            .coupon {
                display: inline-block;
                font-size: 22px;
                font-family: $headings-font-family;
                font-weight: 600;
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
                vertical-align: middle;

                &:hover {
                    background-color: $sup-orange;
                }
            }

            @include media-breakpoint-down(md) {
                padding-left: 0px;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 0px;
            }
        }
    }
}

@keyframes birdsing_footer {

    100% {
        background-position-x: -234px;
    }
}

@keyframes birdsing_footer_rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
