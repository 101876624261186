﻿/// <reference path = "../../main.scss" />
// File Name: _button.scss
// Description: Button styles
// ------------------------------------------------------------
.button-wrapper.center {
    text-align: center;
    margin-bottom: 30px;
}


.btn {
    font-size: 22px;
    font-family: $headings-font-family;
    font-weight: 600;
    background-color: $sup-slate-blue-pale;
    color: $white;
    padding-left: 1.25em;
    padding-right: 1.25em;
    white-space: normal;

    &:hover {
        background-color: $sup-slate-blue;
        color: $white;
        text-decoration: none;
        border-bottom-color: transparent;
    }

    &.btn-thin {
        padding: .5625rem;
        border-radius: 6px;
        font-size: 15px;
        line-height: 22px;
        font-family: $font-family-base;
        text-transform: uppercase;
        font-weight: normal;
    }
}

.btn.aqua-blue {
    background-color: $sup-aqua-blue-darker;
    color: $white;

    &:active, &:focus {
        background-color: $sup-aqua-blue-active;
        color: $white;
    }

    &:hover {
        background-color: $sup-aqua-blue;
        color: $white;
    }
}

.btn.gray {
    background-color: $sup-site-gray-lighter;
    color: $white;

    &:hover {
        background-color: $sup-site-gray;
        color: $white;
    }
}

.btn.orange {
    background-color: $sup-orange;
    color: $white;

    &:hover {
        background-color: $sup-orange-darker;
        color: $white;
    }
}

.btn.green {
    background-color: $sup-site-green;
    color: $white;

    &:hover {
        background-color: $sup-site-green-lighter;
        color: $white;
    }

    &:active, &:focus {
        background-color: $sup-site-green-active;
        color: $white;
    }
}
