﻿///<reference path="../../main.scss" />
// File Name: _widget-numbered-card.scss
// Description: Styles for the Numbered Card
// ------------------------------------------------------------
.numbered-card {
    text-align: center;
    margin-bottom: 30px;

    .card-number {
        background-color: $sup-orange;
        width: 80px;
        height: 80px;
        display: inline-block;
        border-radius: 40px;
        margin-bottom: 15px;

        .ring {
            border: 4px solid white;
            border-radius: 40px;
            width: 78px;
            height: 78px;
            display: flex;
            margin-top: 1px;
            margin-left: 1px;
            align-items: center;
            justify-content: center;

            span {
                color: $white;
                font-size: 32px;
                font-weight: 600;
                font-family: $font-family-sans-serif;
            }
        }
    }

    .card-title {
        font-size: 28px;
        color: $sup-aqua-blue-darker;
        font-weight: bold;
        font-family: $font-family-sans-serif;
        margin-bottom: 0.5rem;
    }
}
