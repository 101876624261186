﻿///<reference path="../../main.scss" />
// File Name: _grid-editor-pricing-list.scss
// Description: Styles for the pricing list
// ------------------------------------------------------------

.callout-wrapper {
    p {
        font-size: 20px;
        line-height: 28px;
    }

    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .callout-image {
        @include make-col(5);
        @include make-col-ready();
        order: 1;
        text-align: center;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        + .callout-content {
            @include make-col(7);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    .callout-content {
        @include make-col(12);

        h2 {
            padding-bottom: 15px;
        }

        .btn-wrapper {
            padding-top: 30px;
            text-align: right;

            @include media-breakpoint-down(sm) {
                padding-bottom: 40px;
                text-align: center;
            }
        }
    }
}

//when the callout with image is placed above callouts with icons, we need to pull the callouts with icons up to blend the 2 sections
.renderCalloutWidget-section {
    + .renderCalloutsWithIconWidget-section {
        margin-top: -55px;
    }
}

.renderCalloutsWithIconWidget-section, .renderCalloutWidget-section {
    .body {
        margin-left: 0;
        margin-right: 0;
    }
}

.renderCalloutWidget {
    &.column.column-12 {
        @media screen and (max-width: 576px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

.renderCalloutsWithIconWidget.column {
    padding-left: 0;
    padding-right: 0;

    .row {
        @media screen and (max-width: 576px) {
            margin-left: 0;
            margin-right: 0;

            .section-heading {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

// callouts with icons grid editor
.clear-swirl {
    background-image: url("/dist/img/blue-wave.png");
    background-repeat: no-repeat;
    background-size: contain;
    //padding-top: 95px;
    padding-top: 5%;

    &.has-heading {
        .blue-bg {
            padding-top: 40px;
        }
    }

    .section-heading {
        flex: 1 1 100%;
        max-width: 100%;
        padding-bottom: 60px;

        h2 {
            color: $white;
            text-align: center;
        }
    }


    .blue-bg {
        background-color: $sup-slate-blue-bg;
        padding-top: 70px;
        padding-bottom: 70px;
        color: $white;
    }

    h3 {
        font-size: 26px;
        line-height: 36px;
        padding-bottom: 10px;
        color: inherit;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
    }

    .callout-item {
        @include make-col(4);
        @include make-col-ready();
        display: flex;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding-bottom: 25px;

            &.has-link {
                padding-bottom: 35px;
            }
        }

        .btn-wrapper {
            a {
                color: $brighter-orange;
                border-bottom-color: $brighter-orange;

                &:hover {
                    color: darken($brighter-orange, 10%);
                    border-bottom-color: darken($brighter-orange, 10%);
                }
            }
        }

        .callout-content {
            p {
                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }
            }
        }

        .callout-icon {
            flex: 1 1 auto;
            text-align: center;

            + .callout-content {
                @include make-col(10);
                padding-left: 10px;

                @include media-breakpoint-down(md) {
                    @include make-col(11);
                }

                @include media-breakpoint-down(sm) {
                    @include make-col(10);
                }
            }
        }
    }
}
