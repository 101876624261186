﻿/// <reference path = "../../main.scss" />
// File Name: _footer.scss
// Description: footer view styles
// ------------------------------------------------------------

footer {
    margin-top: 0rem;
    padding-top: 1rem;
    border-top: 1px solid $sup-footer-gray;
    background-color: $sup-footer-gray;
    color: $white;

    .footer-container {
        background-image: url("/dist/img/laundry_icon_bg.gif");
        background-position: top right;
        background-size: 270px;
        background-repeat: no-repeat;
    }


    .HeaderStyle {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    a {
        &, &:hover {
            color: $sup-aqua-blue;
        }
    }

    .footer-address {
        @include make-col(3);
        @include make-col-ready();

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        font-size: 15px;
    }

    .footer-navigation {
        @include make-col(7);
        @include make-col-ready();

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .dynamic-row {
            @include make-row();
        }

        .nav-wrapper > ul > li > a, .nav-wrapper > ul > li > span.title-no-link {
            color: $sup-footer-light-gray-headings;
            padding: 0;
            margin-bottom: 5px;
        }

        .nav-link:hover {
            text-decoration: underline;
        }

        .nav-wrapper > ul > li > span.title-no-link:hover {
            text-decoration: none;
        }

        ul {
            margin: 0;
            padding: 0;
            width: 100%;

            li {
                list-style-type: none;
                margin-bottom: 5px;
                line-height: 1.4;
                font-size: 14px;
                font-weight: normal;
                color: $sup-footer-dark-gray-blue;

                a.nav-link {
                    color: $sup-footer-dark-gray-blue;
                    padding: 0;
                }
            }

            li.nav-item.root-item {
                font-size: 18px;
                display: inline-block;
                vertical-align: top;
                font-weight: bold;
                margin-bottom: 15px;

                & > a {
                    margin-bottom: 5px;
                    color: $sup-footer-light-gray-headings;
                }

                @include media-breakpoint-up(xs) {
                    @include make-col(12);
                }

                @include media-breakpoint-up(sm) {
                    @include make-col(6);
                }

                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                }

                @include make-col-ready();
                width: 100%;
            }
        }
    }

    .social-media {
        @include make-col(2);
        @include make-col-ready();

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        ul {
            float: right;
            margin: 0px;
            padding: 0;
            padding-right: 15px;

            li {
                list-style-type: none;
                margin: 0px;
                display: inline-block;
                width: 28px;
                height: 28px;
                margin-left: 15px;
                margin-bottom: 15px;

                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border-radius: 14px;
                    background-color: $sup-aqua-blue;
                    text-align: center;
                    vertical-align: middle;

                    .fa {
                        color: $sup-footer-gray;
                        margin-bottom: 2px;
                        vertical-align: middle;
                        font-size: 13px;
                    }

                    &:hover {
                        background-color: $sup-aqua-blue-lighter;

                        .fa {
                        }
                    }
                }
            }
        }
    }

    .disclaimer {
        margin-top: 30px;
        font-size: 12px;
        color: $sup-footer-dark-gray-bluer;

        a {
            &, &:hover {
                color: $sup-footer-dark-gray-blue;
            }
        }

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }


    .footer-container {
        @include make-container();
        @include make-container-max-widths();

        .footer-row {
            @include make-row();
            padding-top: 50px;
            padding-bottom: 50px;


            .footer-column {
                @include make-col(12);
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
    }
}
