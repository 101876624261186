﻿.zipcodecheck {
    max-width: 380px;

    .error-message {
        font-size: 13px;
        color: #8a1f11;
        text-align: left;
        margin-top: -10px;
        margin-bottom: 10px;

        &:empty {
            display: none;
        }
    }

    label {
        //text-transform: uppercase;
        font-weight: bold;
        font-family: "Fira Sans",Arial,sans-serif;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 26px;
    }

    input {
        //background-color: $lp-background-color;
        color: $black;
        font-size: 15px;
        text-align: left;
        margin-bottom: 10px;
        padding: 9px 5px;
        border-radius: 0;
        border: 1px solid rgba(102, 102, 102, 0.5);

        &:focus {
            outline-offset: -2px;
            outline: -webkit-focus-ring-color auto 1px;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: $black;
        }

        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: $black;
            opacity: 1;
        }

        &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: $black;
            opacity: 1;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $black;
        }
    }
}