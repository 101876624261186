﻿///<reference path="../../main.scss" />
// File Name: _googlemaps.scss
// Description: Google Maps Grid Editor (Frontend) view styles
// ------------------------------------------------------------

span.videoWrapper {
    display: block;
}

.videoWrapper {
    position: relative;
    height: 500px;

    &.map {
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
        }

        iframe {
            border: none;
        }
    }

    .mapWrap, object, embed, iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.column-6 {
    .videoWrapper {
        &.map {
            padding-top: 100px;
        }
    }
}

.column-4 {
    .videoWrapper {
        &.map {
            padding-top: 150px;
        }
    }
}

.locations-map .videoWrapper .map_canvas {
    background-color: $sup-aqua-blue;
}

.locations-map {
    position: relative;

    .map-swirl-bg, .map-swirl-bg-gray {
        height: 77px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(/dist/img/map_swirl_bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 2;
        pointer-events: none;
        @include media-breakpoint-down(sm)
        {
            display: none;
        }
    }

    .map-swirl-bg-gray {
        background-image: url(/dist/img/map_swirl_bg_gray.png);
    }
}

.iw-helper {
    //padding-top: 160px;
}
