﻿///<reference path="../../main.scss" />
// File Name: _forms.scss
// Description: Styles for the forms
// ------------------------------------------------------------

.atlwdg-trigger.atlwdg-RIGHT {
    z-index: 999;
}

main {
    .contour {
        padding-bottom: 30px;
        padding-top: 0;

        .multiplechoice {
            &.umbraco-forms-field {
                padding-bottom: 15px;
            }

            span.field-validation-error {
                padding-left: 0;
            }

            label.fieldLabel {
                padding-top: 15px;
                line-height: 16px;
            }

            > span {
                font-size: 12px;
                font-weight: bold;
            }
        }

        span.checkboxlist {
            float: none;
            padding-left: 0;
            padding-bottom: 0;

            label {
                margin-bottom: 0;
                padding-right: 20px;
                font-size: 16px;
            }

            br {
                @media screen and (min-width: 1351px) {
                    display: none;
                }
            }
        }

        fieldset {
            padding-right: 0;
            padding-top: 0;
        }

        .span12.col-md-12 {
            padding-left: 0;
            padding-right: 0;
        }

        select {
            padding: 9px 5px;
            width: 100%;
            border: 1px solid $timberwolf;
            margin-top: 0;
            margin-bottom: 0;
        }

        .contourNavigation {
            .col-md-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }

        input.text, textarea {
            max-width: none !important;
            margin-top: 0;
            border: 1px solid $timberwolf;
            width: 100%;

            &.input-validation-error {
                margin-bottom: 0;
            }
        }

        .umbraco-forms-field {
            padding-bottom: 10px;
        }

        .field-validation-error {
            font-size: 16px;
        }

        textarea {
            height: 200px;
        }

        input.text {
            padding: 9px 5px;
        }

        label.fieldLabel {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 0;
        }

        &.half-width-fields {
            .span12.col-md-12 {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }

            .umbraco-forms-field {
                flex: 0 0 100%;
            }

            .firstname, .lastname, .phone, .email, .preferredlocation, .estimatedfrequency {
                @include media-breakpoint-up(md) {
                    flex: 0 0 48%;
                    max-width: 48%;
                }
            }
        }

        &.green-submit-button {
            .btn.blue.primary {
                background-color: $sup-site-green;
                color: $white;

                &:hover {
                    background-color: $sup-site-green-lighter;
                    color: $white;
                }

                &:active, &:focus {
                    background-color: $sup-site-green-active;
                    color: $white;
                }
            }
        }
    }
}
