﻿/// <reference path = "../../main.scss" />
// File Name: _locationsContainer.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

.hours-line {
    font-size: 16px;
}

.hours-title {
    padding-bottom: 10px;
}

.officeLocationsContainer, .landingPage {
    .image-gallery-list {
        padding-bottom: 20px;
    }

    hr {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    &.subpage {
        main {
            .h2Heading.county-name {
                margin-bottom: 20px;
            }
        }
    }

    .location-container {
        @include make-container();
    }

    .business-hours-payment-row {
        > div {
            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    .business-hours-payment-section {
        .business-hours {
            .notes {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 20px;
                }

                p {
                    color: $tundora;
                    font-size: 14px;
                    margin-bottom: 4px;
                }
            }
        }
    }

    .payment-methods-accepted {
        @include media-breakpoint-down(sm) {
            padding-bottom: 20px;
        }
    }

    .location-list {
        @include make-row();
        margin-top: 10px;

        .county-section {
            @include make-col(6);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }

        .location {
            display: flex;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 10px;
            }

            .count {
                background-color: $sup-orange;
                color: $white;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                position: relative;
                margin-right: 15px;
                margin-top: 8px;

                span.text {
                    margin-top: -2px;
                    font-size: 20px;
                    font-weight: bold;
                }

                span.outline {
                    top: 1px;
                    left: 1px;
                    border: 2px solid $white;
                    width: 48px;
                    height: 48px;
                    border-radius: 24px;
                    position: absolute;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .get-directions {
                .fa {
                    margin-right: 5px;
                }
            }
        }
    }
}
