﻿/// <reference path = "../../main.scss" />
// File Name: _nav-primary.scss
// Description: Primary navigation view styles
// ------------------------------------------------------------

nav.nav-primary {
    font-family: $headings-font-family;
    border: none;
    //border-top: 7px solid $primary-nav-top-border;
    #mobileNavbarToggler {
        width: 72px;
        height: 64px;
        cursor: pointer;

        &, span {
            font-size: 36px;
        }

        .text {
            font-size: 10px;
        }
    }

    .utility-bar {
        @extend .row;
        justify-content: flex-end;

        .utility-col {
            padding: 0 15px;
            display: flex;
            align-items: center;

            + .utility-col {
                padding-left: 20px;
            }
        }

        .utility-item {
            font-size: 14px;
            display: flex;
            align-items: center;

            a {
                color: inherit !important;
                text-decoration: none !important;
            }

            .item-icon {
                float: left;

                .fa {
                    min-width: 25px;
                    padding-right: 10px;
                    text-align: center;
                    font-size: 20px;
                }
            }

            .item-content {
                display: table-cell;
                vertical-align: top;
                height: 100%;
            }

            &.location-link {
                a {
                    font-size: 1rem;
                    //font-family: $font-family-base;
                    color: $sup-drk-aqua-blue !important;

                    &:hover {
                        color: $sup-aqua-blue-darker !important;
                    }

                    > span {
                        border-bottom: 1px dotted !important;
                    }
                }
            }
        }
    }

    .nav-link {
        font-family: $headings-font-family;
        font-size: 14px;
    }

    .navbar-brand {
        padding: 0.75rem 0.25rem !important;
        height: 80px;
        width: auto;
        margin-right: 0;

        img {
            width: auto;
            display: block;
            height: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }

    .nav-top-wrapper {
        @extend .container-fluid;
        max-width: 1600px;
        height: 90px;

        .nav-top {
            @extend .row;
            align-items: center;
            height: 100%;
        }

        .nav-brand-wrapper {
            @extend .col-md-3;
            padding: 0;


            .navbar-brand {
                padding: 0.75rem 0.25rem;
                max-width: 245px;
            }
        }

        .nav-utility-wrapper {
            @extend .col-md-6;

            @include media-breakpoint-up(xl) {
                flex: 0 0 55%;
                max-width: 55%;
            }

            @include media-breakpoint-up(xxl) {
                flex: 0 0 60%;
                max-width: 60%;
            }

            &.nopickupbutton {
                @extend .col-md-9;

                @include media-breakpoint-up(xl) {
                    flex: 0 0 75%;
                    max-width: 75%;
                }
            }
        }

        .schedule-pickup-button {
            @extend .col-md-3;
            padding-left: 20px;

            @include media-breakpoint-up(xl) {
                flex: 0 0 20%;
                max-width: 20%;
            }

            @include media-breakpoint-up(xxl) {
                flex: 0 0 15%;
                max-width: 15%;
            }

            text-align: right;

            a {
                font-size: 1rem;
                font-family: $font-family-base;
            }
        }
    }

    .nav-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-top: $primary-nav-borders solid 1px;
        border-bottom: $primary-nav-borders solid 1px;

        > .collapse {
            width: 100%;
        }
    }

    #primary-collapsing-navbar {

        .nav.navbar-nav {
            top: auto;

            > li.nav-item {
                float: none;
                display: table-cell;

                > a.nav-link {
                    color: $primary-nav-link-color;
                    padding: 0.5rem;
                    font-weight: 500;
                    text-align: center;

                    &:hover {
                        background-color: $primary-nav-dropdown-link-hover-bg;
                        color: $primary-nav-dropdown-link-hover-font-color;
                    }
                }

                &.utility-nav-style {
                    > a.nav-link {
                        background-color: $sup-utility-style-brown;

                        &:hover {
                            background-color: $sup-utility-style-brown-darker;
                        }
                    }
                }
            }
        }
    }

    nav .nav-wrapper #primary-collapsing-navbar > .navbar-nav > .nav-item.dropdown {

        > .nav-link {
            &:hover {
                background-color: $primary-nav-link-hover-bg;
                color: $primary-nav-link-hover-font-color;

                &:after {
                    color: $primary-nav-link-hover-font-color;
                }
            }

            &:after {
                content: "\f0d7";
            }
        }

        > .dropdown-menu {
            background-color: transparent;
            border: 0;
            padding: 0;
            width: 300px;
            left: 0;

            > .dropdown-menu-inner,
            > ul {
                //margin-top: 10px;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background-color: $primary-nav-dropdown-bg;
                //border-color: transparent;
                /*
                &:before {
                    width: 0;
                    height: 0;
                    border: 9.5px solid transparent;
                    border-bottom: 10px solid $primary-nav-dropdown-arrow-color;
                    border-top: 0;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    content: " ";
                    margin-left: -17px;
                    z-index: -1;
                }*/
                li {
                    border: 0;
                    padding: 0 17px;

                    &:hover {
                        background-color: $primary-nav-dropdown-link-hover-bg;
                        border-bottom: 1px solid $primary-nav-link-hover-border;
                        //cover up border above it
                        &:not(:first-child) {
                            margin-top: -1px;
                            padding-top: 1px;
                        }
                        //on hover we want everything to blend
                        padding-left: 0;
                        padding-right: 0;

                        .nav-link {
                            border: none;
                            padding-left: 20px;
                            padding-right: 20px;
                            background-color: $primary-nav-dropdown-link-hover-bg;
                            color: $primary-nav-dropdown-link-hover-font-color;
                        }
                    }

                    .nav-link {
                        padding-left: 0;
                        padding-right: 0;
                        color: $primary-nav-dropdown-link-color;
                        white-space: nowrap;
                        border-bottom: 1px solid $primary-nav-link-border;

                        &:hover {
                            //padding-left: 20px;
                            //padding-right: 20px;
                            background-color: $primary-nav-dropdown-link-hover-bg;
                            color: $primary-nav-dropdown-link-hover-font-color;
                        }
                    }

                    &:last-child .nav-link {
                        border-bottom: 0;
                    }
                }
            }

            .dropdown-menu {
                display: none !important;
            }

            > .dropdown-menu-inner {
                text-align: center;
                padding: 20px;
                width: 180px;

                a {
                    &.btn {
                        min-width: 0;
                        width: 145px;
                    }
                }

                .new-customer {
                    padding-top: 15px;

                    a {
                        font-style: italic;
                        color: $headings-link-color;

                        &:hover, &:active {
                            color: $headings-link-interact-color;
                            background-color: transparent;
                        }
                    }
                }
            }

            &.right {
                left: auto;
                right: 0;
                margin-left: auto;

                > ul {
                    &:before {
                        left: auto;
                        right: 20%;
                        margin-left: 0;
                    }
                }

                > .dropdown-menu-inner {
                    float: right;

                    &:before {
                        left: auto;
                        right: 20%;
                        margin-left: 0;
                    }
                }
            }
        }

        &:hover {
            > .dropdown-menu {
                position: absolute;
            }
        }
    }
}
#primary-navigation {
    @extend .container;
    display: table;
    float: none !important;
}

body.alt-font-1 {
    nav.nav-primary {
        &, & .nav-link {
            font-family: $alt-font-1-headings-font-family;
        }
    }
}

// scss-lint:disable ImportantRule
.navbar-toggleable {
    &-xs {
        @include clearfix;

        @include media-breakpoint-down(xs) {
            .navbar-nav .nav-item {
                float: none;
                margin-left: 0;
            }
        }

        @include media-breakpoint-up(sm) {
            display: block !important;
        }
    }

    &-sm {
        @include clearfix;

        @include media-breakpoint-down(sm) {
            .navbar-nav .nav-item {
                float: none;
                margin-left: 0;
            }
        }

        @include media-breakpoint-up(md) {
            display: block !important;
        }
    }

    &-md {
        @include clearfix;

        @include media-breakpoint-down(md) {
            .navbar-nav .nav-item {
                float: none;
                margin-left: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            display: block !important;
        }
    }
}
// scss-lint:enable ImportantRule
