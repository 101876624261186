﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

.subpage {
    .content-with-sidebar {
        padding-bottom: 40px;
        min-height: 300px;
    }

    .horizontal-rule {
        hr {
            margin-bottom: 2rem;
            margin-top: 2rem;
        }
    }

    .get-directions {
        a {
            color: $elm;

            &:hover {
                color: lighten($elm, 10%);
            }
        }
    }

    .container-inline {
        padding: 1.25rem;
    }

    main {
        //padding-bottom: 40px;
        h1, .h1, h2, .h2 {
            text-align: left;
        }

        .h1Heading, h2 {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 6px;
            line-height: 30px;

            @include media-breakpoint-down(sm) {
                font-size: 28px;
                margin-bottom: 2px;
            }

            ~ p:not(.h1Heading):not(.h2Heading):not(.h3Heading):not(.teal-highlight-heading):not(.blue-highlight-heading) {
                font-size: 18px;

                @include media-breakpoint-down(sm) {
                    font-size: 17px;
                }
            }
        }

        p {
            font-size: 18px;
            line-height: 26px;

            &.larger-copy, .larger-copy {
                font-size: 18px;
                line-height: 30px;

                @include media-breakpoint-down(sm) {
                    font-size: 28px;
                }
            }
        }

        .locations-map p {
            font-size: 16px;
        }

        .media {
            margin-bottom: 25px;
        }

        p {
            margin-bottom: 25px;

            &.h1Heading, &.h2Heading, &.h3Heading {
                font-family: $headings-font-family;
            }
        }

        .h1Heading, .h2Heading, .h3Heading, .teal-highlight-heading, .blue-highlight-heading {
            font-family: $headings-font-family;
        }

        .h2Heading {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            margin-bottom: 2px;
        }

        h2, .h2 {
            font-size: 32px;
            line-height: 36px;
            /*margin-bottom: 0;*/
        }

        .h3Heading {
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            margin-bottom: 0;
        }

        .container-inline {
            padding: 0;
        }

        .content-with-sidebar {
            position: relative;

            .container-nmw {
                @include make-container();
                //@include make-container-max-widths();
                //custom breakpoints are needed to accommodate the design
                max-width: 1140px;

                .sidebar-region {
                    // This provides a max width of 240px for the sidebar on large desktops, floated to the left of the main body.
                    width: 240px;
                    left: auto;
                    right: unquote("calc((100vw - 1170px)/2 + 1155px)");
                }

                @media (max-width: 1650px) {
                    max-width: 1000px;

                    .sidebar-region {
                        right: auto;
                        left: 0;
                        width: unquote("calc((100vw - 1000px - 30px)/2)");
                    }
                }

                @media (max-width: 1500px) {
                    max-width: 900px;

                    .sidebar-region {
                        width: unquote("calc((100vw - 900px - 30px)/2)");
                    }
                }

                @media (max-width: 1400px) {
                    max-width: 800px;

                    .sidebar-region {
                        width: unquote("calc((100vw - 800px - 30px)/2)");
                    }
                }

                @media (max-width: 1350px) {
                    max-width: 700px;

                    .sidebar-region {
                        width: unquote("calc((100vw - 700px - 30px)/2)");
                    }
                }

                @media (max-width: 1200px) {
                    max-width: 945px;

                    .sidebar-region {
                        width: 100%;
                    }
                }

                @media (max-width: 992px) {
                    max-width: 720px;
                }

                @media (max-width: 768px) {
                    max-width: 540px;
                }

                .body {
                    @media (max-width: 600px) {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    > .column {
                        @media (max-width: 600px) {
                            padding-left: 0;
                        }

                        &.macro {
                            @media (max-width: 600px) {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }

            .sidebar-region {
                font-size: 15px;

                p {
                    font-size: 15px;
                    margin-bottom: .75rem;
                }

                h4, .h4 {
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 26px;
                }

                position: absolute;
                left: 0;
                width: 240px;
                padding: 0 15px;

                @media (max-width: 1200px) {
                    position: relative;
                    order: 1;
                    width: auto;
                }

                @include media-breakpoint-down(lg) {
                    display: block;
                    width: 100%;
                    border-top: 1px solid rgba(0,0,0,0.1);
                    margin-top: 15px;
                }

                .body {
                    @media (min-width: 600px) and (max-width: 992px) {
                        margin-left: 0;
                    }
                }
                /*
                .container-inline {
                    padding: 0 15px;

                    @include media-breakpoint-down(md) {
                        padding: 0px;
                    }
                }*/
                > section + section {
                    margin-top: 1rem;
                    padding-top: 2rem;
                    border-top: 2px solid rgba(0,0,0,0.1);
                }
            }

            .content-region {
                @include make-col(12);

                .content-region-inset {
                    max-width: 1140px;
                }
            }
        }
    }
}

.question {
    padding: 20px 40px 10px 60px;
    background-color: $black-squeeze;
    position: relative;
    margin-bottom: 20px;
    margin-left: 40px;

    @include media-breakpoint-down(sm) {
        padding: 30px 40px 10px 25px;
        margin-bottom: 25px;
        margin-top: 50px;
        margin-left: 0;
    }

    img {
        position: absolute;
        top: 0;
        left: -40px;

        @include media-breakpoint-down(sm) {
            top: -30px;
            left: 0;
        }
    }
}
