﻿///<reference path="../../main.scss" />
// File Name: _grid-editor-testimonial.scss
// Description: Styles for the testimonial
// ------------------------------------------------------------

.testimonial-section {
    overflow: hidden;

    .testimonial-grid-editor {
        background-color: $white;
        color: $sup-tan-bg-dark-text;
        position: relative;
    }
}
.tan-swirl .testimonial-grid-editor {
    padding: 60px 80px;

    .title {
        color: $sup-slate-blue-pale;
        font-size: 2.25rem;
        font-style: italic;
    }

    .text {
        line-height: 1.85em;
    }

    .author-line {
        text-align: right;
        font-weight: bold;
        margin-top: 1em;
        color: $sup-slate-blue;
    }
}
.blue-swirl .testimonial-grid-editor {
    padding: 80px 90px;
    background-color: $white;

    .title {
        color: $sup-slate-blue-pale;
        font-size: 2.25rem;
        font-style: italic;
    }

    .text {
        line-height: 1.65em;
        font-weight: 500;
        font-size: 22px;
    }

    .author-line {
        text-align: right;
        font-weight: bold;
        margin-top: 1em;
        color: $sup-slate-blue;
    }

    @include media-breakpoint-down(md) {
        padding: 25px 25px;
        h4.title{
            font-size: 2rem;
        }
    }
}
/* Animations */
.hotair-left-cloud-right {

    .hotair {
        display: block;
        position: absolute;
        background-image: url("/dist/img/hot_air_balloon.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 98px;
        height: 158px;
        top: 10%;
        left: -25px;
        animation: hotair-left-cloud-right-hb 5.5s ease-in-out infinite;
    }

    .cloud {
        display: block;
        position: absolute;
        background-image: url("/dist/img/row-anim-cloud1.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 0;
        width: 164px;
        height: 76px;
        top: 35%;
        right: -80px;
        /*animation: hotair-left-cloud-right-cl 5.5s linear infinite;*/
    }

    @include media-breakpoint-down(md) {

        .hotair {
            width: 68px;
            left: -45px;
        }

        .cloud {
            width: 114px;
        }
    }
}
@keyframes hotair-left-cloud-right-hb {
    0% {
        top: 7%;
    }

    50% {
        top: 14%;
    }

    100% {
        top: 7%;
    }
}
@keyframes hotair-left-cloud-right-cl {
    12.5% {
        right: -110px;
        top: 35%;
    }
    25% {
        right: -112px;
        top: 34%;
    }
    37.5% {
        right: -115px;
        top: 33%;
    }
    50% {
        right: -117px;
        top: 32%;
    }
    62.5% {
        right: -117px;
        top: 32%;
    }
    75% {
        right: -115px;
        top: 33%;
    }
    87.5% {
        right: -112px;
        top: 34%;
    }

    100% {
        right: -110px;
        top: 35%;
    }
}