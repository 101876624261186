﻿///<reference path="../../main.scss" />
// File Name: _grid-editor-image-gallery.scss
// Description: Styles for the testimonial
// ------------------------------------------------------------
.image-gallery-grid-editor {
    .image-gallery-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;

        a {
            display: block;
            margin: 15px;
            border-bottom: none;
        }

        @include make-row();

        @include media-breakpoint-up(xs) {
            margin-left: -10px;
            margin-right: -10px;
        }

        @include media-breakpoint-up(sm) {
            margin-left: -15px;
            margin-right: -15px;
        }

        .image-gallery-item {
            .overlay {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0,0,0,0.0);
                z-index: 2;
            }

            a {
                display: block;
                margin: 5px;
                position: relative;

                &:hover {
                    .overlay {
                        background-color: rgba(0,0,0,0.3);
                    }
                }

                img {
                    width: 100%;
                }
            }

            @include make-col(6);

            @include media-breakpoint-up(xs) {

                a {
                    margin: 10px;
                }
            }

            @include media-breakpoint-up(sm) {
                @include make-col(4);

                a {
                    margin: 15px;
                }
            }

            @include media-breakpoint-up(md) {
                @include make-col(3);
            }



            @media (min-width: 1200px) {
                flex: 0 0 auto;
                display: inline-block;

                a img {
                    width: auto;
                }
                /*@include make-col(2);*/
            }
        }
    }
}
