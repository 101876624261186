.multilevelpushmenu_wrapper {
	position: absolute;
	overflow: hidden;
	min-width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
}

.multilevelpushmenu_wrapper .levelHolderClass {
	position: absolute;
	overflow: hidden;
	top: 0;
	background-color: #336ca6;
	width: auto;
	min-height: 100%;
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 1em;
	zoom: 1;
}

.multilevelpushmenu_wrapper .ltr {
	margin-left: -100%;
	left: 0;
	-moz-box-shadow: 5px 0 5px -5px #1f4164;
	-webkit-box-shadow: 5px 0 5px -5px #1f4164;
	box-shadow: 5px 0 5px -5px #1f4164;
	filter: progid:DXImageTransform.Microsoft.Shadow(color=#1f4164,direction=90,strength=2);
}

.multilevelpushmenu_wrapper .rtl {
	margin-right: -100%;
	right: 0;
	-moz-box-shadow: 5px 0 5px 5px #1f4164;
	-webkit-box-shadow: 5px 0 5px 5px #1f4164;
	box-shadow: 5px 0 5px 5px #1f4164;
	filter: progid:DXImageTransform.Microsoft.Shadow(color=#1f4164,direction=270,strength=2);
}

.multilevelpushmenu_wrapper .multilevelpushmenu_inactive {
	background-color: #2e6196;
}

.multilevelpushmenu_wrapper h2 {
	font-size: 1.5em;
	line-height: 1em;
	font-weight: bold;
	color: #1f4164;
	padding: 0 .4em 0 .4em;
}

.multilevelpushmenu_wrapper ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.multilevelpushmenu_wrapper li {
	cursor: pointer;
	border-top: 1px solid #295685;
	padding: .4em .4em .4em .4em;
}

.multilevelpushmenu_wrapper li:last-child {
	border-bottom: 1px solid #295685;
}

.multilevelpushmenu_wrapper li:hover {
	background-color: #295685;
}

.multilevelpushmenu_wrapper a {
	display: block;
	outline: none;
	overflow: hidden;
	font-size: 1.5em;
	line-height: 1em;
	padding: .2em .2em;
	text-decoration: none;
	color: #fff;
}

.multilevelpushmenu_wrapper a:hover {
	color: #ffe;
}

.multilevelpushmenu_wrapper .backItemClass {
	display: block;
	padding: .4em .4em .4em .4em;
	background-color: #2e6196;
	border-top: 1px solid #295685;
}

.multilevelpushmenu_wrapper .floatRight {
	float: right;
}

.multilevelpushmenu_wrapper .floatLeft {
	float: left;
}

.multilevelpushmenu_wrapper .cursorPointer {
	cursor: pointer;
}

.multilevelpushmenu_wrapper .iconSpacing_ltr {
	padding: 0 .4em 0 0;
}

.multilevelpushmenu_wrapper .iconSpacing_rtl {
	padding: 0 0 0 .4em;
}