﻿///<reference path="../../main.scss" />
// File Name: _locationDetails.scss
// Description: Styles for the Location Details Page
// ------------------------------------------------------------

.subpage {
    &.officeLocation {
        hr {
            margin-bottom: 2rem;
        }

        .content-region {

            .big-heading {
                font-size: 32px;
                margin-bottom: 3px;

                @include media-breakpoint-down(sm) {
                    font-size: 26px;
                }
            }

            .location {

                @include make-col(12);
                display: flex;
                flex-wrap: wrap;

                .location-img {
                    @include make-col-ready();
                    @include make-col(8);
                    order: 1;
                    margin-bottom: 30px;
                    padding-right: 0;
                    text-align: right;

                    @media (max-width: 1400px) {
                        @include make-col(6.5);
                    }

                    @media (max-width: 768px) {
                        @include make-col(12);
                        text-align: left;
                        padding-left: 0;
                    }

                    + .location-data {
                        @include make-col(4);

                        @media (max-width: 1400px) {
                            @include make-col(5.5);
                        }

                        @media (max-width: 768px) {
                            @include make-col(12);
                        }
                    }
                }

                .location-data {
                    @include make-col(12);
                    line-height: 30px;

                    .get-directions {
                        .fa {
                            margin-right: 5px;
                        }
                    }

                    .business-hours-payment-row {
                        > div {
                            @include make-col(12);
                        }
                    }
                }

                .business-hours {
                    padding-top: 25px;

                    h2 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    .hours-line {
                        font-size: 16px;
                    }

                    .notes {
                        p {
                            font-size: 14px;
                            margin-bottom: 5px;
                            color: $tundora;
                            line-height: 18px;
                        }

                        margin-top: 5px;
                        margin-bottom: 15px;
                    }
                }

                .payment-methods-accepted {
                    h2 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }

                .payment-method {
                    margin-right: 10px;

                    img {
                        max-width: 42px;
                    }
                }
            }
        }
    }
}
