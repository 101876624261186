﻿/// <reference path = "../../main.scss" />
// File Name: _landingPage.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

.landingPage {

    main {
        p.larger-copy {
            font-size: 20px;
        }

         @include media-breakpoint-down(sm) {
            .h1Heading{
                margin-bottom: 10px;
            }
        }
    }
    .locations-section {
        padding-top: 60px;

        .locations-content-container {
            @include make-container();
            @include make-container-max-widths();

            @include media-breakpoint-up(xl) {
                max-width: 991px;
            }
        }

        .locations-intro {
            padding-bottom: 35px;
        }

        .location-list {
            margin-top: 0;

            .location {
                margin-bottom: 20px;
                font-size: 16px;
                flex: 0 0 50%;

                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                    margin-bottom: 40px;
                }
            }
        }
    }

    .locations-map {
        margin-bottom: -40px;
    }

    @include media-breakpoint-down(sm) {
        .locations-section {
            padding-top: 30px;

            .locations-intro {
                padding-bottom: 12px;
            }
        }
    }
}
