﻿///<reference path="../../main.scss" />
// File Name: _grid-editor-pricing-list.scss
// Description: Styles for the pricing list
// ------------------------------------------------------------

.pricing-list-wrapper {
    display: flex;
    flex-flow: row wrap;
    padding-top: 20px;

    &.space-bot {
        padding-bottom: 35px;
    }

    .disclaimer-wrapper {
        flex: 1 1 100%;
    }

    .disclaimer-center {
        text-align: center;
        position: relative;
        font-size: 14px;
        line-height: 22px;
        flex: 1 1 100%;
        color: $tundora;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            max-width: 70%;
        }

        @include media-breakpoint-down(sm) {
            padding-bottom: 20px;
        }

        margin: 0 auto;

        span {
            color: $java-2;
            display: inline-block;
            padding-right: 5px;
        }
    }

    .pricing-list {
        @include make-col(6);
        @include make-col-ready();

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        h2 {
            margin-left: -15px;
            margin-right: -15px;
            padding-bottom: 20px;
        }


        &:first-child {
            @include media-breakpoint-up(md) {
                padding-right: 35px;
            }
        }

        &:last-child {
            @include media-breakpoint-up(md) {
                padding-left: 35px;
            }
        }

        &.second {

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;

                h2 {
                    padding-top: 20px;
                }
            }
        }



        ul {
            padding-left: 0;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }

            li {
                position: relative;
                list-style: none;
                display: flex;
                justify-content: space-between;
                padding-bottom: 5px;
                flex-wrap: wrap;

                .dots {
                    overflow: hidden;
                    flex: 1 1;

                    &:before {
                        content: '....................................................................................................................................';
                    }
                }

                p {
                    @include media-breakpoint-down(md) {
                        font-size: 17px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                    }

                    &:first-child {
                        font-weight: 300;
                        flex: 0 1 auto;
                    }

                    span {
                        color: $java-2;
                        font-size: 16px;
                    }

                    strong {
                        display: inline-block;
                        white-space: nowrap;
                    }

                    margin-bottom: 0;
                }

                &:before {
                    content: "*";
                    position: absolute;
                    left: -15px;
                    top: 3px;
                    color: $java-2;
                }
            }
        }
    }
}
