﻿.cardEditor, .widgetPicker {
    .card {
        margin-bottom: 1rem;
        flex-wrap: wrap;
    }

    .card-inner {
    }

    .card-block {
        padding: 10px;
    }

    .card-text {
        margin-bottom: .75rem;

        &:last-child, > :last-child {
            margin-bottom: 0 !important;
        }
    }

    .card-title {
    }
}
